<template>
  <div>
    <div class="row mb-3">
      <!-- Sky map -->
      <div class="col-lg-12">
        <sky-map v-if="chartReady" :coordinates="coordinates" :tooltip="onMeteorClicked" />
      </div>
    </div>

    <div class="row-md">
      <!-- Google map heatmap -->
      <div class="card mx-auto chart-gradient fadeIn" style="color:white;">
        <div class="card-header">Krysspeilingsdekning</div>
        <GoogleMapHeatmap v-if="chartReady" :coordinates="coordinates">
        </GoogleMapHeatmap>
      </div>
      <!--End google map heatmap -->
    </div>
  </div>
</template>

<script>
import GoogleMapHeatmap from "@/components/visualization/GoogleMapHeatmap.vue";
import SkyMap from "@/components/visualization/SkyMap.vue";
import { mapGetters } from "vuex";

export default {
  name: "Meteorkart",
  components: {
    GoogleMapHeatmap,
    SkyMap,
  },

  props: {

    openMeteorModal: {
      type: Function,
      required: true,
    },

  }
  ,


  computed: {
    ...mapGetters("dashboard", [
      "coordinates",
      "chartReady",
    ]),
  },
  methods: {
    /**
    * Handle meteor click event, fetch the meteor data, and show the modal.
    * @param {number} meteorId - The clicked meteor's ID.
    * @this {VueComponent}
    */
    onMeteorClicked(meteorId) {
      this.openMeteorModal(meteorId)
    },
  },
};
</script>

<style scoped>
.fadeIn {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

</style>

