<template>  
    <div ref="legend"></div>  
</template>
  
<script>

import * as d3 from 'd3';

import { meteorColor } from '@/components/helpers/astrofunctions';

export default {
  name: 'SkyMapLegend', 
  mounted() {
     this.drawLegend(this.$refs.legend); 
  },
  methods: {  

    drawLegend(legendContainer) {
      const legendWidth = 135;
      const legendHeight = 170;
      const legendMargin = { top: 3, right: 3, bottom: 3, left: 3 };

      const legend = d3.select(legendContainer)
        .append("svg")
        .attr("width", legendWidth)
        .attr("height", legendHeight)
        .style("position", "absolute") 
        .style("bottom", "50px")
        .style("right", "0px")
        .style("background-color", "rgb(80, 122, 180)"); 

       

      const lineHeight = 18;
      let currentY = 10;

      // Stars and star sizes
      legend.append("circle")
        .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 2)
        .style("fill", "white");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .style("color", "white")
        .text("Stjerne");

      currentY += lineHeight;

      // Sporadic Meteor 
      legend.append("circle")
        .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", "white")
        .style("stroke", "black")
        .style("stroke-width", "0.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text("Sporadisk meteor");

      currentY += lineHeight;

    

      // Meteor from meteor shower
      legend.append("circle")
        .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", "none")
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text("Meteor fra meteorsverm");

      currentY += lineHeight;
  

   
      
      // Meteor color based on speed
      var colorExampleSpeed = 17;
      var exampleColor = meteorColor(colorExampleSpeed);


      legend.append("circle")
      .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", exampleColor)
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text("Hastighet 0-18 km/s");

      currentY += lineHeight;


       

      colorExampleSpeed = 24;
      exampleColor = meteorColor(colorExampleSpeed);

      legend.append("circle")
      .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", exampleColor)
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text(`Hastighet 18-25 km/s`);
      currentY += lineHeight;


      colorExampleSpeed = 33;
      exampleColor = meteorColor(colorExampleSpeed);

      legend.append("circle")
      .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", exampleColor)
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text(`Hastighet 25-34 km/s`);
      currentY += lineHeight;

      colorExampleSpeed = 54;
      exampleColor = meteorColor(colorExampleSpeed);

      legend.append("circle")
      .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", exampleColor)
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text(`Hastighet 34-55 km/s`);
      currentY += lineHeight;

      colorExampleSpeed = 88;
      exampleColor = meteorColor(colorExampleSpeed);

      legend.append("circle")
      .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", exampleColor)
        .style("stroke", "white")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text(`Hastighet > 55 km/s `);
      currentY += lineHeight; 


         // Meteor from meteor shower with low end height
         legend.append("circle")
        .attr("cx", legendMargin.left + 10)
        .attr("cy", currentY)
        .attr("r", 5)
        .style("fill", "white")
        .style("stroke", "red")
        .style("stroke-width", "1.5");

      legend.append("text")
        .attr("x", legendMargin.left + 20)
        .attr("y", currentY + 4)
        .style('fill', 'white')
        .style('font-size', '10px')
        .text("h<30km og v<30km/s");

      currentY += lineHeight;


    }
  }




};
</script>
  
<style>

legend {
  color: white;  
}
</style>
  