import * as d3 from 'd3';
import starsData from '@/components/helpers/stars.js';

export default class SkyMap {
    constructor(container, coordinates, width = 400, height = 600, tooltip) {
        this.container = container;
        this.width = width;
        this.height = height;
        this.svg = null;
        this.SkyMapLegend = null;
        this.meteors = [];
        this.stars = [];
        this.projection = null;
        this.rotate = { x: 0, y: 45 };
        this.path = null;
        this.graticule = null;
        this.ecliptic = null;
        this.containerWidth = 0;
        this.currentTooltip = null;
        this.currentCircle = null;
        this.currentCircleX = 0;
        this.currentCircleY = 0;
        this.currentTooltipWidth = 0;
        this.currentTooltipHeight = 0;
        this.meteors = coordinates;
        this.initializeMap();
        this.tooltip = tooltip;
    }

    setCoordinates(newCoordinates) {
        this.meteors = newCoordinates
            .filter((point) => point.ProperTriangulation == "1")
            .map((point) => ({
                id: point.id,
                lng: point.radiant_ra,
                lat: point.radiant_dec,
                ra: point.radiant_ra,
                dec: point.radiant_dec,
                radiant_shower: point.radiant_shower,
                track_speed: point.track_speed,
                track_endheight: point.track_endheight,
                date: point.date,
            }));
    }



    callOnMeteorClick(id) {
        if (typeof this.tooltip === 'function') {
            this.tooltip(id);
        } else {
            console.error('tooltip is not a function' + this.tooltip);
        }
    }

    initializeMap() {
        this.svg = this.createSvg();
        this.containerWidth = this.container.clientWidth;
        this.setupProjection();
        this.path = d3.geoPath(this.projection);
        this.graticule = d3.geoGraticule().step([15, 15]);
        this.fetchData();
        this.draw();
    }

    draw() {
        this.drawGraticule(this.svg, this.projection, this.graticule, this.path);
        this.drawStars(this.container, this.projection, this.ecliptic, this.stars);
        this.drawMeteors(this.container, this.meteors, this.projection, this.hideTooltipFunction);
    }

    createSvg() {
        return d3
            .select(this.container)
            .append('svg')
            .attr('width', '100%')
            .attr('height', this.height)
            .style('background-color', 'rgb(1, 30, 87)')
            .call(d3.drag().on('drag', this.dragged.bind(this)));
    }
    setupProjection() {
        this.projection = d3
            .geoStereographic()
            .scale(3 * this.height / Math.PI)
            .translate([this.containerWidth / 2, this.height / 2])
            .clipAngle(120)
            .rotate([this.rotate.x, -this.rotate.y])
            .center([0, 0]);
        ;
    }

    fetchData() {
        this.stars = starsData.map(star => ({
            name: star.ProperName,
            lng: 180 - (360 * parseFloat(star.RA)) / 24,
            lat: parseFloat(star.Dec),
            colorIndex: parseFloat(star.ColorIndex),
            mag: parseFloat(star.Mag),
        }));
    }

    dragged(event) {
        this.hideTooltip();
        this.updateRotation(event);
        this.draw();
    }


    updateRotation(event) {
        this.rotate.x += event.dx / 2;
        this.rotate.y += event.dy / 2;
        this.projection.rotate([this.rotate.x, -this.rotate.y]);
    }



    starColor(colorIndex) {
        const colorScale = d3.scaleSequential()
            .domain([1.42, -0.3])
            .interpolator(d3.interpolateRdYlBu)
        return colorScale(colorIndex);
    }

    formatDateForThumbnail(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const second = String(date.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}${hour}${minute}${second}`;
    }

    capitalizeWords(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }


    showTooltip(map, event, meteor) {
        this.currentTooltip = d3.select(map)
            .append('div')
            .attr('class', 'radiant-shower-tooltip')
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('padding', '2px')
            .style('border-radius', '3px')
            .style('font-size', this.height > 500 ? '16px' : '12px')

        // Calculate the image URL
        const formattedDate = this.formatDateForThumbnail(meteor.date);
        const imageUrl = 'https://ildkule.net/img/' + formattedDate + '_thumbnail.jpg';

        const tooltipScale = this.height > 500 ? 1.1*this.height/500 : 1; // Scale the tooltip if the map is larger than 500px

        // Append the image to the tooltip
        this.currentTooltip.append('img')
            .attr('src', imageUrl)
            .style('width', 180 * tooltipScale + 'px') // Set the width of the image width scaling
            .style('height', 'auto'); // Set the height of the image to maintain aspect ratio

        // Create a container for the tooltip text
        const textContainer = this.currentTooltip.append('div');

        this.currentCircle = d3.select(event.target);
        const circleX = parseFloat(this.currentCircle.attr('cx'));
        const circleY = parseFloat(this.currentCircle.attr('cy'));
        const tooltipWidth = this.currentTooltip.node().offsetWidth;
        const tooltipHeight = this.currentTooltip.node().offsetHeight;

        let left = circleX + 10;
        if (left + tooltipWidth > window.innerWidth) {
            left = circleX - tooltipWidth - 10;
        }

        let top = circleY - tooltipHeight - 10;
        if (top < 0) {
            top = circleY + 10;
        }

        this.currentTooltip
            .style('left', left + 'px')
            .style('top', top + 'px')
            .style('color', "black");

        const text = meteor.radiant_shower
            ? `${this.capitalizeWords(meteor.radiant_shower)} <br> Ekvatorialkoordinater  <br>for radianten: ${meteor.ra}, ${meteor.dec} <br> Gj. hastighet: ${meteor.track_speed} km/s<br>Siste obs. høyde: ${meteor.track_endheight} km<br>${meteor.date} UTC`
            : `Sporadisk meteor <br>  Ekvatorialkoordinater  <br>for radianten: ${meteor.ra}, ${meteor.dec}<br>Gj. hastighet: ${meteor.track_speed} km/s<br>Siste obs. høyde: ${meteor.track_endheight} km<br>${meteor.date}  UTC`;

        textContainer.html(text); // Use the .html() method to set the inner HTML of the text container, including line breaks

        return this.currentTooltip, this.currentCircle, circleX, circleY, tooltipHeight;
    }


    /**
    * Hides the radiant shower tooltip.
    */
    hideTooltip() {
        if (this.currentTooltip) {
            this.currentTooltip.remove();
            this.currentTooltip = null;
            this.currentCircle = null;
        }

    }

    onTooltipClick(meteorId) {
        this.$emit('meteor-clicked', meteorId);
    }

    clearMeteors(container) {
        const svg = d3.select(container);
        svg.selectAll('circle').remove();
        svg.selectAll('path').remove();
    }

    uniqueMeteorId(d, i) {
        return `meteor_${i}_${d.lat}_${d.lng}`;
    }

    /**
    * Draws meteors on the map based on the fetched data.
    *
    * @param {object} map - The map object.
    * @param {object[]} meteors - The meteor data.
    * @param {object} projection - The projection object.
    */
    drawMeteors() {
        const svg = d3.select(this.container).select('svg');

        const filteredMeteors = this.meteors
            .filter((point) => point.ProperTriangulation == "1")
            .map((point) => ({
                id: point.id,
                lng: point.radiant_ra,
                lat: point.radiant_dec,
                ra: point.radiant_ra,
                dec: point.radiant_dec,
                radiant_shower: point.radiant_shower,
                track_speed: point.track_speed,
                track_endheight: point.track_endheight,
                date: point.date,
            }));

        svg
            .selectAll('circle.meteor')
            .data(filteredMeteors)
            .join('circle')
            .attr('class', 'meteor')
            .attr('cx', d => {
                const map_ra = 360 - ((d.lng + 180) % 360);
                const map_dec = d.lat;
                return this.projection([map_ra, map_dec])[0];
            })
            .attr('cy', d => {
                const map_ra = 360 - ((d.lng + 180) % 360);
                const map_dec = d.lat;
                return this.projection([map_ra, map_dec])[1];
            })
            .attr('r', d => d.track_endheight < 30 && d.track_speed < 30 ? 8 : 5)
            .style('fill', d => d.radiant_shower ? this.meteorColor(d.track_speed) : 'white')
            .style('stroke', d => d.track_endheight < 30 && d.track_speed < 30 ? 'red' : (d.radiant_shower ? 'white' : 'black'))
            .style('stroke-width', d => d.track_endheight < 30 && d.track_speed < 30 ? 1.5 : (d.radiant_shower ? 1 : 0.5))
            .on('mouseover', (event, d) => this.showTooltip(this.container, event, d))
            .on('mouseout', () => this.hideTooltip())
            .style('pointer-events', 'all')
            .on('click', (event, d) => {
                if ('ontouchstart' in document.documentElement) {
                    this.hideTooltip(); // Hide tooltip on mobile click to remove the previous tooltip
                    this.showTooltip(this.container, event, d); // Show tooltip on mobile click
                    event.stopPropagation(); // Prevent triggering the callOnMeteorClick method
                } else {
                    this.callOnMeteorClick(d.id); // Trigger the callOnMeteorClick method on other devices
                }
            });
    }




    /**
    * Draws stars on the map based
    * on the star data file.
    *
    * @param {object} map - The map object.
    * @param {object} projection - The projection object.
    * @param {object} ecliptic - The ecliptic object.
    * @param {object[]} stars - The star data.
    */
    drawStars(container, projection, ecliptic, stars) {
        const svg = d3.select(container).select('svg');
        svg.selectAll('.star').remove();
        svg.selectAll('.star-label').remove();

        this.drawGraticule(svg, this.projection, this.graticule, this.path);

        const brightestStars = stars
            .sort((a, b) => a.mag - b.mag)
            .slice(0, 75);

        svg
            .selectAll('.star')
            .data(brightestStars)
            .enter()
            .append('circle')
            .attr('class', 'star')
            .attr('cx', d => projection([d.lng, d.lat])[0])
            .attr('cy', d => projection([d.lng, d.lat])[1])
            .attr('r', d => 3 * (1 / (Math.log(d.mag + 5))))
            .attr('fill-opacity', 1)
            .style('z-index', 10)
            .style('fill', d => this.starColor(d.colorIndex));

        svg
            .selectAll('.star-label')
            .data(brightestStars)
            .enter()
            .append('text')
            .attr('class', 'star-label')
            .attr('x', d => projection([d.lng, d.lat])[0] + 10)
            .attr('y', d => projection([d.lng, d.lat])[1] - 10)
            .style('fill', 'white')
            .style('font-size', '10px')
            .text(d => d.name);

        // Move the ecliptic line drawing after the stars
        /* this.drawEclipticLine(svg, projection); */
    }

    drawEclipticLine(svg, projection) {
        const ecliptic = d3.geoPath().projection(projection);
        const eclipticLine = this.generateEclipticLine();

        svg
            .append("path")
            .datum({
                type: "LineString",
                coordinates: eclipticLine
            })
            .attr("d", ecliptic)
            .style("stroke", "darkred")
            .style("fill", "none")
            .style("stroke-width", 1.5);
    }


    /**
    * Generates the ecliptic line coordinates.
    *
    * @returns {Array} - An array of ecliptic line coordinates.
    */
    generateEclipticLine() {
        const obliquity = 23.44; // Earth's axial tilt in degrees
        const coordinates = [];
        for (let ra = 0; ra <= 360; ra += 1) {
            const raRad = (ra * Math.PI) / 180;
            const dec = Math.asin(Math.sin(obliquity * (Math.PI / 180)) * Math.sin(raRad));
            const decDeg = (dec * 180) / Math.PI;
            coordinates.push([ra, decDeg]);
        }
        return coordinates;
    }

    /**
    * Draws graticule lines and labels on the map.
    */
    drawGraticule(svg, projection, graticule, path) {
        svg.selectAll('.graticule-label').remove(); // Remove existing labels
        svg.selectAll('path').remove();
        // Draw graticule lines
        svg
            .append('path')
            .datum(graticule)
            .attr('d', path)
            .style('fill', 'none')
            .style('stroke', 'lightgray')
            .style('stroke-width', 0.5);
        // Add RA labels at every 1-hour interval
        for (let i = 0; i < 24; i++) {
            const ra = 180 - (i * 15);
            const dec = 0;
            svg
                .append('text')
                .attr('class', 'graticule-label')
                .attr('x', projection([ra, dec])[0] + 5)
                .attr('y', projection([ra, dec])[1] + 15)
                .style('fill', 'white')
                .style('font-size', '10px')
                .text(`${i}h`);
        }


        // Add Dec labels at every 15-degree interval
        for (let i = -90; i <= 90; i += 15) {
            svg
                .append('text')
                .attr('class', 'graticule-label')
                .attr('x', projection([0, i])[0] + 5)
                .attr('y', projection([0, i])[1] - 5)
                .style('fill', 'white')
                .style('font-size', '10px')
                .text(`${i}°`);
        }
    }

    /**
      * Returns the color of a meteor based on its speed.
      *
      * @param {number} speed - The speed of the meteor in km/s.
      * @returns {string} - The color of the meteor as a hex string.
      *
      * @description
      * This function generates a color for a meteor based on its speed, using a color-blind-friendly color palette. The function calculates the index of the color to use based on the speed of the meteor, and returns the corresponding color from the palette as a hex string.
      *
      * @example 
      * // Generate a color for a meteor with speed 50 km/s
      * const color = meteorColor(50);
      * console.log(color); // #FE6100
      */
    meteorColor(speed) {

        const palette = [
            '#648FFF', // 0-18 km/s
            '#785EF0', // 18-25 km/s
            '#DC267F', // 25-34 km/s
            '#FE6100', // 34-55 km/s
            '#FFB000', // 55+ km/s
        ];

        let index;
        if (speed < 18) {
            index = 0;
        } else if (speed < 25) {
            index = 1;
        } else if (speed < 34) {
            index = 2;
        } else if (speed < 55) {
            index = 3;
        } else {
            index = 4;
        }

        return palette[index];
    }

}