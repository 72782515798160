<template>
  <div >    
    <div class="form-check form-switch">      
      <input type="checkbox" class="form-check-input" id="show-lines-switch" v-model="showLines" />
      <label class="form-check-label" for="show-lines-switch">Vis meteorer</label>
    </div>
    <div ref="mapRef"  style="width: 100%; height: 35em;"></div> 
  </div>
</template>

<script>
import loadGoogleMapsApi from "load-google-maps-api";

export default {
  name: "GoogleMapHeatmap",
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mapRef: null,
      maps: null,
      heatmap: null,
      map: null,
      markers: [],
      lines: [],
      showLines: false,
    };
  },
  watch: {
    showLines() {
      this.toggleLines();
    },
    async coordinates(newCoordinates) {
      if (this.maps && newCoordinates.length) {
        this.updateHeatmap(newCoordinates);
        /* this.addMarkers(newCoordinates, this.map); */
        this.addLines(newCoordinates, this.map);
      }
    },
  },

  
  async created() {
    this.maps = await loadGoogleMapsApi({
      key: "AIzaSyCWllD35fUNznv4rkuHztTSWz-B0glDAII",
      libraries: ["visualization", "geometry"],
    });

    this.initMap(this.coordinates);
  },
  methods: {
    initMap(coordinates) {
      const norway = { lat: 65, lng: 16 };

      this.map = new this.maps.Map(this.$refs.mapRef, {
        zoom: 4.2,
        center: norway,
        mapTypeId: "terrain",
      });

      this.heatmap = new this.maps.visualization.HeatmapLayer({
        data: coordinates
          .filter((point) => point.lat && point.lng && point.SourceBadDetection === '0' && point.ProperTriangulation === '1')
          .map(
            (point) =>
              new this.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng))
          ),
        map: this.map,
      });

      this.setHeatmapOptions();

      //this.addMarkers(coordinates, this.map);

      this.addLines(coordinates, this.map);
      this.toggleLines(); // Call toggleLines here to hide lines initially
    },

    addLines(coordinates, map) {
      // Remove existing lines from the map
      this.lines.forEach((line) => line.setMap(null));

      // Clear the lines array
      this.lines = [];

      coordinates
        .filter((point) => point.lat && point.lng && point.slat && point.slng && point.SourceBadDetection === '0' && point.ProperTriangulation === '1')
        .forEach((point) => {
          const start = new this.maps.LatLng(parseFloat(point.slat), parseFloat(point.slng));
          const end = new this.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng));

          // Divide the line into multiple segments with different opacities
          const numSegments = 10; // Adjust the number of segments for the desired gradient effect
          const opacityStep = 1 / numSegments;
          for (let i = 0; i < numSegments; i++) {
            const segmentStart = this.maps.geometry.spherical.interpolate(start, end, i / numSegments);
            const segmentEnd = this.maps.geometry.spherical.interpolate(start, end, (i + 1) / numSegments);

            const polyline = new this.maps.Polyline({
              path: [segmentStart, segmentEnd],
              geodesic: true,
              strokeColor: `rgba(255, 255, 255, ${(i + 1) * opacityStep})`,
              strokeOpacity: 0.8,
              strokeWeight: 2.5,
              map: map,
            });

            // Store the polyline object in the lines array
            this.lines.push(polyline);
          }

          // Add a small circle at the end of the line
          const circle = new this.maps.Circle({
            center: end,
            radius: 1500, // Adjust the radius for desired size
            fillColor: 'white',
            fillOpacity: 0.8, // Adjust fillOpacity for the end circle
            strokeWeight: 0,
            map: map,
          });

          // Store the circle object in the lines array
          this.lines.push(circle);
        });
    },


    addMarkers(coordinates, map) {
      this.clearMarkers(); // Add this line to clear old markers before adding new ones
      coordinates
        .filter((point) => point.lat && point.lng && point.SourceBadDetection === '0')
        .forEach((point) => {
          const marker = new this.maps.Marker({
            position: new this.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng)),
            map: map,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
              scaledSize: new this.maps.Size(10, 10), // Adjust the size (width, height) to make the markers smaller
            },
          });

          this.markers.push(marker); // Add this line to store the marker in the markers array
        });
    },

    clearMarkers() {
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];
    },

    updateHeatmap(coordinates) {
      this.heatmap.setData(
        coordinates
          .filter((point) => point.lat && point.lng && point.SourceBadDetection === '0' && point.ProperTriangulation === '1')
          .map(
            (point) =>
              new this.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng))
          )
      );
    },

    toggleLines() {
      this.lines.forEach((line) => line.setMap(this.showLines ? this.map : null));
    },

    setHeatmapOptions() {
      const gradient = [
        "rgba(0, 255, 255, 0)",
        "rgba(0, 255, 255, 1)",
        "rgba(0, 191, 255, 1)",
        "rgba(0, 127, 255, 1)",
        "rgba(0, 63, 255, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(0, 0, 223, 1)",
        "rgba(0, 0, 191, 1)",
        "rgba(0, 0, 159, 1)",
        "rgba(0, 0, 127, 1)",
        "rgba(63, 0, 91, 1)",
        "rgba(127, 0, 63, 1)",
        "rgba(191, 0, 31, 1)",
        "rgba(255, 0, 0, 1)",
      ];

      this.heatmap.set("dissipating", false);
      this.heatmap.set("radius", 1);
      this.heatmap.set("gradient", gradient);
      this.heatmap.set("opacity", 0.4);
    },
  },
};
</script>
