<template>
  <div class="card  chart-gradient fadeIn" style="color:white;">
    <div class="card-header">Radiantkart</div>
    <div class="card-img-top">
      <div class="map-container" ref="mapContainer" style="position: relative;">
        <div ref="map"></div>
        <div ref="legend">
          <SkyMapLegend></SkyMapLegend>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="card-text">
        <em>Radiant for en meteor er punktet på himmelen hvor meteoren synes å komme fra. Klikk på en meteor for å se
          mer
          informasjon om den.</em>
      </p>
    </div>

  </div>
</template>
  
<script>
/*
 ============================================
  SkyMap Vue Component (for Ildkule)
 ============================================
 This SkyMap component is a Vue.js implementation of an interactive
 celestial map using d3 and a stereographic projection, made for Ildkule. 
 It displays stars, meteors, and graticule lines, allowing users to 
 rotate the map and view detailed information about meteors.
 
 Features:
  - Fetches meteor data from an external API and displays it on the map
  - Displays the brightest stars in the sky
  - Shows the name of a star when hovering over it
  - Displays radiant shower information for meteors on hover
  - Draws graticule lines and labels for right ascension and declination
  - Supports dragging the map to rotate the view and update the projection
  - Dynamically updates the map elements based on the current rotation
  - Visualizes the ecliptic line on the map
 ============================================
 */

import starsData from '@/components/helpers/stars.js';
import SkyMapLegend from "@/components/visualization/SkyMapLegend.vue";
import SkyMap from '@/components/visualization/SkyMapClass.js';

export default {
  name: 'SkyMap',
  components: {
    SkyMapLegend

  },
  props: {
    coordinates: {
      type: Array,
      required: true,
    },

    tooltip: {
      type: Function,
      required: true,
    },


  },
  data() {
    return {
      skyMapInstance: null,
      SkyMapLegend,
      meteors: [],
      stars: [],
    };
  },
  mounted() {
    this.fetchData();

    // Set initial height based on device screen height
    let initialHeight = window.innerHeight > 500 ? window.innerHeight - 300 : 500;

    // Add event listener for window resize
    window.addEventListener('resize', () => {
      // Update the height based on device screen height
      initialHeight = window.innerHeight > 500 ? window.innerHeight - 300 : 500;

      // Update the SkyMap instance with the new height
      this.skyMapInstance.height = initialHeight;
      this.$nextTick(() => {
        this.skyMapInstance.update();
      });
    });

    this.skyMapInstance = new SkyMap(this.$refs.map, this.coordinates, this.$refs.mapContainer.clientWidth, initialHeight, this.tooltip);
  },

  watch: {

    coordinates: function (newCoordinates) {
      if (this.skyMapInstance) {
        this.skyMapInstance.meteors = newCoordinates;
        this.$nextTick(() => {
          this.skyMapInstance.update();
        });
      }
    }
  },

  methods: {

    /**
    * Sets up the stars data from star file.
    * Fetches meteor data from an external API and updates the meteors array.
    * After fetching the data, it draws meteors and stars on the map.
    */
    fetchData() {

      this.stars = starsData.map(star => ({
        name: star.ProperName,
        lng: 180 - (360 * parseFloat(star.RA)) / 24,
        lat: parseFloat(star.Dec),
        colorIndex: parseFloat(star.ColorIndex),
        mag: parseFloat(star.Mag),
      }));

    },

  }
}

</script>
  
<style>
.star {
  stroke: rgb(255, 255, 255);
  stroke-width: 0.5;
}

.star-name-tooltip {
  pointer-events: none;
}

legend {
  color: white;
}

.marks .sphere {
  fill: #2244c2;
  position: fixed !important;
  left: 0px !important;
  margin-left: 0px !important;
}

.marks .graticule {
  fill: none;
  stroke: #C0C0BB;
}

.marks .feature {
  fill: #137B80;
  stroke: #C0C0BB;
}

.marks .interiors {
  fill: none;
  stroke: #169ca3;
}

.fadeIn {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
  