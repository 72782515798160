/**
  * Returns the color of a meteor based on its speed.
  *
  * @param {number} speed - The speed of the meteor in km/s.
  * @returns {string} - The color of the meteor as a hex string.
  *
  * @description
  * This function generates a color for a meteor based on its speed, using a color-blind-friendly color palette. The function calculates the index of the color to use based on the speed of the meteor, and returns the corresponding color from the palette as a hex string.
  *
  * @example 
  * // Generate a color for a meteor with speed 50 km/s
  * const color = meteorColor(50);
  * console.log(color); // #FE6100
  */
export function meteorColor(speed) {
    // Define the color palette
    const palette = [
        '#648FFF', // 0-18 km/s
        '#785EF0', // 18-25 km/s
        '#DC267F', // 25-34 km/s
        '#FE6100', // 34-55 km/s
        '#FFB000', // 55+ km/s
    ];

    // Calculate the index of the color to use based on speed
    let index;
    if (speed < 18) {
        index = 0;
    } else if (speed < 25) {
        index = 1;
    } else if (speed < 34) {
        index = 2;
    } else if (speed < 55) {
        index = 3;
    } else {
        index = 4;
    }

    // Return the color from the palette
    return palette[index];
}


/**
* Returns the color of a star based on its color index.
*
* @param {number} colorIndex - The color index of the star.
* @returns {string} - The color of the star.
*/
import * as d3 from 'd3';
export function starColor(colorIndex) {
    const colorScale = d3.scaleSequential()
        .domain([1.42, -0.3])
        .interpolator(d3.interpolateRdYlBu)
    return colorScale(colorIndex);
}

/**
 * Returns the coordinates of the planets in the solar system.    
 * 
 * @returns {Promise} - A promise that resolves to an array of planet coordinates.
 */
export async function getPlanetCoordinates() {
    const planets = ['mercury', 'venus', 'earth', 'mars', 'jupiter'];

    const apiUrl = `http://api.open-notify.org/planets.json`;

    const response = await fetch(apiUrl);

    const data = await response.json();

    const planetData = data.planets.filter(({ name }) => planets.includes(name.toLowerCase()));

    return planetData.map(({ name, ra, dec }) => ({ id: name, ra, dec }));
}


